import { useEffect } from 'react';
import { QuestionState } from '../../utilities/types';
import './Question.css';

const Question = (props: QuestionState) => {
    const { activeQuestion, updateSubmission, selectedOption } = props;

    // JAVASCRIPT HACKY SOLUTION
    useEffect(() => {
        const options = document.querySelector('.options') as HTMLElement;
        if (activeQuestion.question === 'what activities do you do in your leggings?') {
            options.classList.add('options-grid');
        } else {
            options.classList.remove('options-grid');
        }
    });

    return (
        <div className='question-container'>
            <h3 className='question-text'>
                {activeQuestion.question}
                <span>{activeQuestion.subtext}</span>
            </h3>
            {/* TODO: convert divs to li and ul elements */}
            <div className='options'>
                {activeQuestion.options.map((option) => (
                    <label className='option' key={option.answerText}>
                        <input
                            type='checkbox'
                            value={option.answerText}
                            checked={selectedOption.includes(option.answerText)}
                            disabled={selectedOption.length >= 3 && !selectedOption.includes(option.answerText)}
                            onChange={updateSubmission}
                        />
                        <span className='answer-choice'>{option.answerText}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default Question;
