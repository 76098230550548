export const questions = [
    {
        question: 'what activities do you do in your leggings?',
        subtext: '(choose your top 3)',
        options: [
            { answerText: 'running', legging: 'nike go' },
            { answerText: 'strength training', legging: 'nike go' },
            { answerText: 'HIIT', legging: 'nike go' },
            { answerText: 'spin', legging: 'nike go' },
            { answerText: 'pilates', legging: 'nike zenvy' },
            { answerText: 'yoga', legging: 'nike zenvy' },
            { answerText: 'hiking', legging: 'nike universa' },
            { answerText: 'walking', legging: 'nike zenvy' },
            { answerText: 'dance', legging: 'nike universa' },
            { answerText: 'barre', legging: 'nike universa' },
        ],
        allowMultiple: true,
    },
    {
        question: 'what emotion do you want to feel when wearing your leggings?',
        options: [
            { answerText: 'energized', legging: 'nike go' },
            { answerText: 'relaxed', legging: 'nike zenvy' },
            { answerText: 'versatile', legging: 'nike universa' },
        ],
        allowMultiple: false,
    },
    {
        question: 'now talking about the fabric, how do you like your leggings to feel on your skin?',
        options: [
            { answerText: 'smooth', legging: 'nike universa' },
            { answerText: 'locked in', legging: 'nike go' },
            { answerText: 'buttery soft', legging: 'nike zenvy' },
        ],
        allowMultiple: false,
    },
    {
        question: 'what size(s) would you like to try on?',
        options: [
            { answerText: 'XS', legging: null },
            { answerText: 'S', legging: null },
            { answerText: 'M', legging: null },
            { answerText: 'L', legging: null },
            { answerText: 'XL', legging: null },
        ],
        allowMultiple: true,
    },
    {
        question: 'what length(s) would you like to try on?',
        options: [
            { answerText: 'full', legging: null },
            { answerText: '7/8', legging: null },
            { answerText: 'crop', legging: null },
        ],
        allowMultiple: true,
    },
];
