import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initMixpanel } from './utilities/mixpanel';

import IntroPage from './pages/IntroPage/IntroPage';
import LandingPage from './pages/LandingPage/LandingPage';
import QuizPage from './pages/QuizPage/QuizPage';
import ResultsPage from './pages/ResultsPage/ResultsPage';

import './App.css';

const App = () => {
    initMixpanel();

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/intro' element={<IntroPage />} />
                <Route path='/quiz' element={<QuizPage />} />
                <Route path='/results' element={<ResultsPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
