import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import Logo from '../../components/Logo/Logo';

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <div className='landing-container'>
            <div className='landing-logo'>
                <Logo width={280} />
            </div>
            <button
                className='get-started'
                onClick={() => {
                    navigate('/intro');
                }}>
                get started
            </button>
        </div>
    );
};

export default LandingPage;
