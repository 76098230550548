import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
    console.log(`Initializing mixpanel with ${import.meta.env.VITE_MIXPANEL_TOKEN}`);
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        track_pageview: true,
        persistence: 'localStorage',
    });
};

export const trackMixpanel = (eventName: string, properties: any) => {
    if (import.meta.env.VITE_MIXPANEL_TOKEN) {
        mixpanel.track(eventName, properties);
    }
};
