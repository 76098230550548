import Logo from '../../components/Logo/Logo';
import { useNavigate } from 'react-router-dom';

import './IntroPage.css';
import { trackMixpanel } from '../../utilities/mixpanel';

const QuizPage = () => {
    const navigate = useNavigate();

    const continueClick = () => {
        trackMixpanel('Start Quiz', {});
        navigate('/quiz');
    };

    return (
        <div className='intro-container'>
            <Logo width={140} />

            <h2 className='description'>
                <span>you're about to discover your new favorite legging.</span>
                <span>answer the following questions to discover the perfect legging for your lifestyle.</span>
            </h2>
            <button onClick={continueClick}>Continue</button>
        </div>
    );
};

export default QuizPage;
