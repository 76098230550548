import { useLocation } from 'react-router-dom';

import Logo from '../../components/Logo/Logo';
import { orderBySize } from '../../utilities/utilities';

import './ResultsPage.css';

const ResultsPage = () => {
    const { state } = useLocation();
    const size = orderBySize(state.submittedData[3]).join(', ');
    const length = state.submittedData[4].join(', ');

    return (
        <div className={`results-container ${state.score}`}>
            <Logo width={140} />

            <div className='content'>
                <div className='result-text'>
                    <h2>
                        <p className='smaller'>your perfect match is the</p>
                        <p>{state.score} legging</p>
                    </h2>
                    <h3 className='show-result-text'>
                        show your result to an attendant to get set up with your fitting room.
                    </h3>
                </div>
                <div className='fit-container'>
                    <h3 className='fit'>
                        size:<br></br>
                        <span className='size-text'>{size}</span>
                    </h3>
                    <h3 className='fit'>
                        length:<br></br>
                        <span className='size-text'>{length}</span>
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default ResultsPage;
