import { useState } from 'react';

import Logo from '../../components/Logo/Logo';
import Question from './Question';
import { questions } from '../../assets/questions';
import { trackMixpanel } from '../../utilities/mixpanel';
import { useNavigate } from 'react-router-dom';

import './QuizPage.css';

const QuizPage = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [submittedData, setSubmittedData] = useState<{ [key: number]: string[] }>({
        ...new Array(questions.length).fill([]),
    });
    const navigate = useNavigate();

    const updateSubmission = (e: any) => {
        const { value, checked } = e.target;

        if (questions[currentQuestion].allowMultiple) {
            if (checked) {
                setSubmittedData({ ...submittedData, [currentQuestion]: [...submittedData[currentQuestion], value] });
            } else {
                setSubmittedData({
                    ...submittedData,
                    [currentQuestion]: [...submittedData[currentQuestion].filter((val: string) => val !== value)],
                });
            }
        } else {
            setSubmittedData({ ...submittedData, [currentQuestion]: [value] });
        }
    };

    const calculateScore = () => {
        let leggingsScore: { [legging: string]: number } = {
            'nike go': 0,
            'nike universa': 0,
            'nike zenvy': 0,
        };
        questions.forEach((question, index) => {
            let answer = submittedData[index];

            trackMixpanel('Answered Question', {
                question: question.question,
                answer: answer.toString(),
            });

            question.options.forEach((option) => {
                if (answer.includes(option.answerText) && option.legging !== null) {
                    leggingsScore[option.legging]++;
                }
            });
        });

        const result = Object.keys(leggingsScore).reduce((a, b) => {
            if (leggingsScore[a] > leggingsScore[b]) {
                return a;
            } else if (leggingsScore[a] === leggingsScore[b]) {
                return Math.random() >= 0.5 ? a : b;
            } else {
                return b;
            }
        });

        trackMixpanel('Legging Result', {
            result: result,
        });

        navigate('/results', {
            state: {
                score: result,
                submittedData: submittedData,
            },
        });
    };
    return (
        <div
            className='quiz-container'
            // Purpose: Reposition background as users move through quiz
            style={{ backgroundPosition: `${(100 * currentQuestion) / (questions.length - 1)}%` }}>
            <div className='header'>
                <Logo width={140} />

                <div className='progress-bar'>
                    {questions.map((question, index) => (
                        <hr
                            key={question.options[0].answerText}
                            className={index === currentQuestion ? 'active bar' : 'bar'}
                        />
                    ))}
                </div>
            </div>

            <div className='question-with-button-container'>
                <Question
                    currentQuestion={currentQuestion}
                    activeQuestion={questions[currentQuestion]}
                    submittedData={submittedData}
                    updateSubmission={updateSubmission}
                    selectedOption={submittedData[currentQuestion]}
                />

                <button
                    className='continue-btn'
                    disabled={!submittedData[currentQuestion].length}
                    onClick={() =>
                        currentQuestion < questions.length - 1
                            ? setCurrentQuestion((prevQuestion) => prevQuestion + 1)
                            : calculateScore()
                    }>
                    Continue
                </button>
            </div>
        </div>
    );
};

export default QuizPage;
